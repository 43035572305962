import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Tabs, TextInput } from "flowbite-react";
import { IoRefreshCircleSharp } from "react-icons/io5";
import * as Sentry from '@sentry/react';

import { getUserAvatarId, isUserInitialised, setUserAvatarId, useAppDispatch, useAppSelector } from "../../store/store";
import { getUserName, setUserName } from "../../store/store";
import { useCreateRoomMutation } from "../../store/apis/pictoPartyApi";
import { createRandomRoomPayload, getARandomNonRepeatingUserName, isEmptyString, useAvatarSelection } from "../../common/utils";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";
import MyButton from "../ui/Button";
import MyAvatar from "../ui/MyAvatar";
import { MAX_AVATAR_ICONS_DEFINED } from "../../../../../common/Constants";
import { MdOutlinePublic } from "react-icons/md";
import PublicRoomInfoComponent from "../PublicRoomInfoComponent";
import BuyMeACoffeeButton from "../BuyMeCoffeeButton";
import FeedbackModal from "../FeedbackModal";

function Home() {
  const reduxUserName = useAppSelector(getUserName);
  const [localUserName, setLocalUserName] = useState(getARandomNonRepeatingUserName());
  const avatarIdStored = useAppSelector(getUserAvatarId);
  const [isDetailUnfilled, setIsDetailUnfilled] = useState(false);
  const [createRoom, {isLoading:isLoadingCreateRoom}] = useCreateRoomMutation();
  const isUserInit = useAppSelector(isUserInitialised);
  const navigate = useNavigate();
  const {state: routerState} = useLocation();
  const dispatch = useAppDispatch();

  const { avatarId, handleChangeAvatar } = useAvatarSelection(
    MAX_AVATAR_ICONS_DEFINED,
    avatarIdStored || Math.floor(Math.random() * MAX_AVATAR_ICONS_DEFINED) + 1
  );
  
  const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUserName(event.target.value));
    setLocalUserName(event.target.value);
    // avatar is guaranteed non-empty, but name can be empty.
    setIsDetailUnfilled(isEmptyString(event.target.value) ? true: false);
  };

  const handleCreateOrJoinRoom = async () => {
    let localInitNeeded = false;
    if (!isUserInit && !isEmptyString(localUserName)) {
      // userName not pushed in redux (as user never entered name or edited generated name)
      // but component local userName present. So set this as user name and let user continue:
      dispatch(setUserName(localUserName));
      localInitNeeded = true;
    }
    if (!isUserInit && avatarId) {
      // Same logic as local name above:
      dispatch(setUserAvatarId(avatarId));
      localInitNeeded = true;
    }

    // If still user not init in redux, and no local init needed too, 
    // then it is issue (name totally erased, etc). Error out.
    if (!isUserInit  && !localInitNeeded) {
      setIsDetailUnfilled(true);
      return;
    }

    // Uninitialised user visiting the room url, 
    // but has now filled info and got initialised,
    // redirect to the room waiting lobby:
    if(routerState?.path){
      navigate(routerState?.path);
      return;
    }

    // Create room & redirect the creator to the room waiting lobby:
    try {
      const response = await createRoom(createRandomRoomPayload()).unwrap();
      const roomId = response.roomId;
      if (!roomId) {
        console.error("No roomID in create_room response");
        Sentry.captureException("No roomID in create_room response");
        return;
      }
      Sentry.captureMessage(`Room created!: ${roomId}`);
      navigate(`/room?roomId=${roomId}`);
    } catch (e) {
      Sentry.captureException(`Error during create room!: ${JSON.stringify(e, null, 2)}`);
      toast.error(`Error during create room!`);
      console.error(`Error during create room: ${e}`);
    }
  };


  return (
    <>
    <FeedbackModal/>
      <div className="flex min-h-screen items-center justify-center bg-slate-200 dark:bg-slate-800 text-slate-900">
        <div className="mx-auto max-w-screen-md">
          {/** 
           * Logo */}
          <div className="m-2 flex justify-center">
            <div className="m-2 p-2 bg-slate-100 rounded-lg border-4 hover:scale-105 hover:bg-slate-300 border-slate-900 flex gap-1 justify-stretch">
              <div><img src="dd_logo.png" alt="Draw Detective logo" className="h-10 w-10"/></div>
              <div className="text-2xl">Draw Detective</div>
            </div>
          </div>
          {/**
           * How to Play
           */}
          <div className="m-2 flex flex-wrap items-center justify-center">
            {/* Avatar Img */}
            <div className="h-64 min-w-[300px] max-w-[330px] flex-grow bg-green-200 w-1/2">
              <div className="flex flex-col h-full place-content-center p-1">
                {/* Directions: Whether Creating / Joining room */}
                {routerState?.path ? 
                  <div className="text-center text-sm"><span className="font-bold">Joining a private room? </span> <br/>1️⃣ Pick your name & avatar, 2️⃣ then join the room!</div>
                : <div className="text-center text-sm"><span className="font-bold">Creating a private room? </span> <br/> 1️⃣ Pick your name & avatar, 2️⃣ then create private room,  3️⃣ send invite link to others!</div>}
                <div className="flex flex-row flex-wrap items-center">
                  <div className="relative w-16 h-16 m-4">
                    <MyAvatar avatarId={avatarId} />
                    <button
                      onClick={handleChangeAvatar}
                      className="bg-black hover:text-gray-400 text-white rounded-full absolute -bottom-2 -right-2"
                    >
                      <IoRefreshCircleSharp className="w-6 h-6" />
                    </button>
                  </div>
                  {/* User Name */}
                  <TextInput
                    type="text"
                    value={reduxUserName || localUserName}
                    onChange={handleUserNameChange}
                    className="max-w-fit"
                  />
                </div>
                <div className="mx-auto">
                  {isDetailUnfilled ? <div className="text-sm font-semibold text-red-700">🙏 Name or Avatar cannot be empty!</div> : <div> <br/></div>}
                </div>
                <div className="flex justify-around place-items-center">
                  <div>
                    <MyButton primary onClick={handleCreateOrJoinRoom} disabled={isLoadingCreateRoom}>
                      {" "}
                      {`${routerState?.path?"Join ":"Create "}`} Room {isLoadingCreateRoom && <LoadingSpinner />}
                    </MyButton>
                  </div>
                  <div>
                    <BuyMeACoffeeButton/>
                  </div>
                </div>
              </div>
            </div>

            <div className="h-64 min-w-[300px] max-w-[330px] flex-grow bg-red-300 w-1/2 p-2 place-content-center space-y-2 text-sm">
              <span className="font-bold">How to play?</span>
              <p>Draw Detective is a free pictionary-style multiplayer game.</p>
              <p>Players take turns drawing a word while others try to guess it to earn points!</p>
              <p>Players with the highest score at the end is declared the winner.</p>
              <p>Create/Join a private room with others, or use an existing public room. Enjoy!</p>
            </div>
            <div className="h-fit min-w-[300px] max-w-[330px] sm:max-w-[660px] flex-grow bg-blue-200 m-2">
            <Tabs aria-label="Public rooms" style="fullWidth">
              <Tabs.Item active title="Join public rooms instead" icon={MdOutlinePublic}>
                <PublicRoomInfoComponent /> 
              </Tabs.Item>
            </Tabs>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
}

export default Home